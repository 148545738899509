<template>
  <div class="login-layout">
    <form id="form-escolha" v-show="primeiroLogin == null">
      <button type="button" id="fazer-login" class="btn btn-primary" @click="primeiroLogin = false">FAZER LOGIN</button>
      <button type="button" id="primeiro-login" class="btn btn-primary" @click="primeiroLogin = true">É MEU PRIMEIRO ACESSO</button>
    </form>
    <form class="form-entrar" v-show="primeiroLogin == false" @submit.prevent="logar">
      <div>
        <button @click="!primeiroLogin" style="border: none; background-color: white">
          < voltar</button>
      </div>
      <h2>Login</h2>
      <input class="input-login" type="text" placeholder="Email ou CPF" v-model="emailOuCpf" autofocus>
      <input class="input-login" type="password" placeholder="Senha" v-model="senha">
      <div id="div-links">
        <p><a class="link" href="#">Esqueci minha senha</a></p>
        <p><router-link class="link" to="/cadastro">Não tenho cadastro</router-link></p>
      </div>
      <input class="submit-login" type="submit" value="entrar">
    </form>
    <form v-show="primeiroLogin == true">
      <primeiro-acesso />
    </form>
  </div>
</template>

<script>
  import PrimeiroAcesso from '@/components/PrimeiroAcesso.vue';
  export default {
    data() {
      return {
        emailOuCpf: '',
        senha: '',
        primeiroLogin: null,
        emailEnviado: false,
      };
    },
    components:{
      PrimeiroAcesso
    },
    methods: {
      async logar(event) {
        event.preventDefault();
        try {
          const token = btoa(`${this.emailOuCpf}:${this.senha}`);

          const response = await this.$api.post('/autenticacao', {}, {
            headers: {
              'Authorization': `Basic ${token}`
            }
          });

          const authToken = response.data.token;
          window.localStorage.setItem('auth_token', authToken);
          this.$router.push('/');
        } catch (error) {
          console.error('Erro ao fazer login:', error);
          console.log('Error Response Data:', error.response?.data);
        }
      }
    }
  }

</script>

<style scoped>
.login-layout {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: #009c8a;
  color: #dadce7;
  height: 100vh;
  display: grid;
  grid-template-columns: 500px;
  grid-template-rows: auto;
  justify-content: center;
  align-content: center;
}

#submit-login:hover {
  background: #00ffe1;
  color: #000000;
  transition-duration: 0.5s;
}

#div-links {
  text-align: center;
}

.link {
  text-decoration: none;
}

#form-escolha{
  display: flex;
  flex-direction: column;
  align-items: center;
}

#fazer-login{
  width: 100%;
  height: 12vh;
  background-color: #00665a;
  border: none;
  font-size: x-large;
}

#primeiro-login{
  background-color: #009c8a;
  border-color: #00665a;
  width: 85%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}
</style>

<style>
.submit-login {
  border: none;
  font-size: 1.3rem;
  font-weight: 100;
  color: #ffffff;
  background: #009c8a;
  width: 75%;
  padding: 0;
  height: 150%;
  border-radius: 0.6rem;
  transition-duration: 0.5s;
}

.form-entrar {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-gap: 3rem;
  justify-items: center;
  background: #fff;
  border-radius: 0.4rem;
  padding: 1.5rem 0rem;
  box-shadow: 2px 8px 11px rgba(0, 0, 0, 0.2);
}

.form-entrar h2 {
  padding: 0;
  margin: 0;
  font-size: 60px;
  color: #02564d;
}

.input-login {
  border: #eaeaea;
  border-bottom-style: solid;
  font-size: 1.3rem;
  font-weight: 100;
  color: #000;
  width: 85%;
  padding: 0.5%;
  padding-left: 1%;
  height: 150%;
  border-radius: 0.6rem;
  outline-color: #009c8a;
}

#div-validacao {
  border: none;
}
</style>

<template>
  <div>
      <Menu />
      <router-view />
  </div>
</template>

<script>
import Menu from '../components/MenuComponent.vue'
import Footer from '../components/FooterComponent.vue'

export default {
  components: {
    Menu,
    Footer
  }
}
</script>

<style scoped>

</style>

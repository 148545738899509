import axios from 'axios';

function interceptors() {
    const config = setting => setting
    const error = erro => {
      const invalidToken = "Token expirado"
      const extractMessage = erro?.response?.data?.message || null
      const message = extractMessage
  
      if( invalidToken.match(message) ) {
        window.localStorage.removeItem("auth_token")
        window.postMessage("login-expirado") 
      }
      return Promise.reject(erro.response)
    }
  
    return { config, error }
}

const api = () => {
    const token = window.localStorage.getItem("auth_token")
    const apiBaseUrl = "https://gex.nerds.cloudns.be/api";

    const configAxios = axios.create({
      baseURL: apiBaseUrl,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ... token ?  { 'Authorization' : `Bearer ${token}` } : {},
      },
    })
    
    const { config, error } = interceptors()
    configAxios.interceptors.response.use(config, error)
  
    return configAxios
}

export const apiPlugin = {
    install(app, options) {
      app.config.globalProperties.$api = api()
    }
}
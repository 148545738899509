<template>
    <form class="form-entrar" @submit.prevent="enviar">
        <div>
            <button @click="!primeiroLogin" style="border: none; background-color: white">
                < voltar</button>
        </div>
        <h3>Primeiro acesso</h3>
        <input class="input-login" type="text" placeholder="Digite aqui seu CPF" @keyup="verificarCpf"
            v-maska="'###.###.###-##'" v-model="cpf" autofocus>
        <input v-show="!emailEnviado" class="submit-login" type="submit" value="enviar">
        <div v-show="emailEnviado">
            <div class="container height-100 d-flex justify-content-center align-items-center">
                <div class="position-relative">
                    <div id="div-validacao" class="card p-2 text-center">
                        <h6>Um código de verificação foi enviado para seu email</h6>
                        <div> <span>O código foi enviado par o email:</span> <small>example@gmail.com</small> </div>
                        <div id="otp" class="inputs d-flex flex-row justify-content-center mt-2"> <input
                                class="m-2 text-center form-control rounded" type="text" id="first" maxlength="1" />
                            <input class="m-2 text-center form-control rounded" type="text" id="second" maxlength="1" />
                            <input class="m-2 text-center form-control rounded" type="text" id="third" maxlength="1" />
                            <input class="m-2 text-center form-control rounded" type="text" id="fourth" maxlength="1" />
                            <input class="m-2 text-center form-control rounded" type="text" id="fifth" maxlength="1" />
                            <input class="m-2 text-center form-control rounded" type="text" id="sixth" maxlength="1" />
                        </div>
                        <div class="mt-4"> <button class="submit-login">Validar</button> </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import LoginLayout from '@/layouts/LoginLayout.vue';
import { vMaska } from 'maska/vue';
export default {
    data() {
        return {
            cpf: '',
            cpfCorreto: false,
            primeiroLogin: LoginLayout.primeiroLogin,
            emailEnviado: false,
        }
    },
    methods: {
        verificarCpf() {
            if (this.cpf.length == 14) {
                this.cpfCorreto = true
            } else {
                this.cpfCorreto = false;
            }
        },
        enviar(event) {

            event.preventDefault();
            if (this.cpfCorreto) {
                this.emailEnviado = true;
                console.log("Email enviado");
                //Implementar o envio do email para o usuario
            } else {
                alert("para enviar, digite seu cpf com todos os números");
            }
        }
    },
    directives: {
        maska: vMaska
    }
}

</script>

<style scoped>
.form-entrar h3 {
    padding: 0;
    margin: 0;
    font-size: 40px;
    color: #02564d;
}

.submit-login[disabled] {
    background-color: rgb(208, 208, 208);
}
</style>
<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-md-8 text-center">
        <h1 class="display-4">Bem-vindo {{userName}}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import {jwtDecode} from 'jwt-decode'

export default {
  data() {
    return {
      userName: null
    }
  },
  created() {
    const token = window.localStorage.getItem('auth_token')
    if (token) {
      try {
        const decodedToken = jwtDecode(token)
        this.userName = decodedToken.sub
      } catch (error) {
        console.error('Erro ao decodificar o token:', error)
      }
    }
  }
}
</script>

<style scoped>

</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { apiPlugin } from './router/api'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

window.addEventListener("message", (event) => {
  if (event.data === "login-expirado") {
    router.push({ name: "login" });
  }
});

createApp(App)
  .use(router)
  .use(apiPlugin)
  .mount('#app')
